import React from 'react'
import {
  CFView,
  CFImage,
  CFSelect,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  mobilePromotion,
  promotions,
  promoVegan,
  mobilePromoVegan,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let promoImage = null
        let promoMobileImage = null
        if (locationId === 'S9ioaKm2suFF6O2pcnnC') {
          promoImage = promoVegan
          promoMobileImage = mobilePromoVegan
        } else {
          promoImage = null
          promoMobileImage = null
        }
        return (
          <CFView>
            <MobileScreen>
              <CFView column center>
                <CFImage src={promoMobileImage} w="95%" alt="Promotions" />
              </CFView>
            </MobileScreen>
            <DefaultScreen>
              <CFView column center m="0 auto" maxWidth="1100px" pv="30px">
                <CFImage src={promoImage} w="100%" alt="Promotions" />
              </CFView>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
