import React from 'react'
import {
  CFView,
  CFImage,
  CFSelect,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  foodGallery,
  foodGalleryVegan,
  mobileGallery,
  mobileGalleryVegan,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let galleryImage = null
        let galleryMobileImage = null
        if (locationId === 'S9ioaKm2suFF6O2pcnnC') {
          galleryImage = foodGalleryVegan
          galleryMobileImage = mobileGalleryVegan
        } else {
          galleryImage = foodGallery
          galleryMobileImage = mobileGallery
        }
        return (
          <CFView>
            <MobileScreen>
              <CFView column center>
                <CFImage src={galleryMobileImage} w="95%" alt="Food Gallery" />
              </CFView>
            </MobileScreen>
            <DefaultScreen>
              <CFView column center maxWidth="1400px">
                <CFImage
                  src={galleryImage}
                  w="90%"
                  alt="Food Gallery"
                  m="0 auto"
                  pv="30px"
                />
              </CFView>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
