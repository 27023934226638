import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, tiktok } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h2 futura bold color="#FFBB00" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center white bold>
            <CFLink href="https://www.facebook.com/p/Ogenki-Sushi-Fusion-Vancouver-BC-100070117455139/?paipv=0&eav=AfZCclCi3ZzfYwvjRxZ4ej77RW6WO0RMdeZSk_Ix_twHGgE_hlbxzayHtsq7JLshWbg&_rdr">
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="Ogenki Sushi Facebook"
              />
            </CFLink>
            <CFLink href="https://www.yelp.ca/biz/ogenki-sushi-vancouver">
              <CFImage w="45px" pr="10px" src={yelp} alt="Ogenki Sushi Yelp" />
            </CFLink>
            <CFLink href="https://www.instagram.com/ogenkisushimainst/?hl=en">
              <CFImage
                w="45px"
                pr="10px"
                src={instagram}
                alt="Ogenki Sushi Instagram"
              />
            </CFLink>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView h2 futura bold color="#FFBB00" mb="15px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter white bold>
            <CFLink href="https://www.facebook.com/p/Ogenki-Sushi-Fusion-Vancouver-BC-100070117455139/?paipv=0&eav=AfZCclCi3ZzfYwvjRxZ4ej77RW6WO0RMdeZSk_Ix_twHGgE_hlbxzayHtsq7JLshWbg&_rdr">
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="Ogenki Sushi Facebook"
                hover
              />
            </CFLink>
            <CFLink href="https://www.yelp.ca/biz/ogenki-sushi-vancouver">
              <CFImage
                w="45px"
                pr="10px"
                src={yelp}
                alt="Ogenki Sushi Yelp"
                hover
              />
            </CFLink>
            <CFLink href="https://www.instagram.com/ogenkisushimainst/?hl=en">
              <CFImage
                w="45px"
                pr="10px"
                src={instagram}
                alt="Ogenki Sushi Instagram"
                hover
              />
            </CFLink>
            <CFLink href="https://www.tiktok.com/@ogenkisushivan">
              <CFImage
                w="42px"
                pr="10px"
                src={tiktok}
                alt="Ogenki Sushi tiktok"
                hover
              />
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
