import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  CFSelect,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import ViewMenuButton from './ViewMenuButton'
import {
  downloadAppButton,
  hero,
  heroVegan,
  heroText,
  logo,
  mobileHero,
  mobileHeroVegan,
  mobileHeroText,
  mobileOrderButton,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let heroImage = null
        let heroMobileImage = null
        if (locationId === 'S9ioaKm2suFF6O2pcnnC') {
          heroImage = heroVegan
          heroMobileImage = mobileHeroVegan
        } else {
          heroImage = hero
          heroMobileImage = mobileHero
        }
        return (
          <CFView>
            <MobileScreen>
              <CFView
                h="100VH"
                w="100%"
                image={`url(${heroMobileImage}) bottom / cover no-repeat`}
                boxShadow="0 2px 2px rgba(0,0,0,.5)"
                column
                justifyBetween
              >
                <Header />

                <CFView
                  textCenter
                  column
                  center
                  ph="5%"
                  pt="5px"
                  w="100%"
                  maxWidth="1200px"
                  pt="10px"
                  bg="rgba(0,0,0,.7)"
                  bc="#B88F60"
                  bw="1.5px"
                >
                  <CFView column center>
                    <CFView pt="10px">
                      <OrderPickupButton />
                    </CFView>
                    <CFView>
                      <OrderDeliveryButton />
                    </CFView>
                    <CFView mb="10px">
                      <ViewMenuButton />
                    </CFView>
                  </CFView>
                </CFView>
              </CFView>
            </MobileScreen>
            <DefaultScreen>
              <CFView
                h="700px"
                w="100%"
                image={`url(${heroImage}) center / cover no-repeat`}
                style={{ backgroundAttachment: 'fixed' }}
                zIndex={90}
                column
                center
                justifyBetween
              >
                <Header />
                <CFView
                  row
                  center
                  ph="5%"
                  w="100%"
                  maxWidth="1200px"
                  pv="5px"
                  bg="rgba(0,0,0,.7)"
                  bc="#B88F60"
                  bw="1.5px"
                >
                  <CFView row center ml="25px">
                    <CFView pt="10px">
                      <OrderPickupButton />
                    </CFView>
                    <CFView pt="10px">
                      <OrderDeliveryButton />
                    </CFView>
                    <CFView mt="3px" ml="6px">
                      <ViewMenuButton />
                    </CFView>
                  </CFView>
                </CFView>
              </CFView>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
