import React, { useState } from 'react'
import {
  CFSelect,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { orderDeliveryButton } from 'images'
import DeliveryModal from './DeliveryModal'
import { dispatch } from 'store'

export default () => {
  const [showModal, setShowModal] = useState(false)
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => (
        <CFView>
          <MobileScreen>
            <CFView hover onClick={() => setShowModal(true)}>
              <CFImage src={orderDeliveryButton} maxWidth="270px" alt="About" />
            </CFView>
          </MobileScreen>
          <DefaultScreen>
            <CFView hover onClick={() => setShowModal(true)}>
              <CFImage src={orderDeliveryButton} maxWidth="260px" alt="About" />
            </CFView>
          </DefaultScreen>
          <DeliveryModal
            showModal={showModal}
            onRequestClose={() => setShowModal(false)}
          />
        </CFView>
      )}
    </CFSelect>
  )
}
