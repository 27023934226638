export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/yelp.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/facebook.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/zomato.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/downloadButton.png'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://d36ru9bg85ogfa.cloudfront.net/viewMenuButton2.webp'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1585671918/cafesaladedefruits/saladedefruits_instagram.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1590261442/general/cflogo.png'
export const contactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1593156320/ogenki/contactbg.png'
export const heroText =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1593156320/ogenki/heroText.png'
export const orderDeliveryButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1601073231/ogenki/orderDeliveryButton.png'
export const orderPickupButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1601073231/ogenki/orderPickupButton.png'
export const mobileContactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1593156320/ogenki/mobileContactbg.jpg'
export const mobileMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1593156320/ogenki/mobileMap.jpg'
export const hero =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1593650441/ogenki/hero.png'
export const heroVegan =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1639012175/ogenki/heroVegan.jpg'
export const mobileHero =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1593652940/ogenki/mobileHero.jpg'
export const mobileHeroVegan =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1639012861/ogenki/mobileHeroVegan.jpg'
export const logo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1593420043/ogenki/logo.png'
export const logoTitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1593420043/ogenki/logoTitle.png'
export const locationMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1594581365/ogenki/locationMap.png'
export const promotions =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1594581365/ogenki/promotions.png'
export const promoVegan =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1639252893/ogenkiVegan/promoVegan.png'
export const about =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1594581365/ogenki/about.png'
export const mobilePromotion =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1594581365/ogenki/mobilePromotion.png'
export const mobilePromoVegan =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1639252893/ogenkiVegan/mobilePromoVegan.jpg'
export const mobileAbout =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1594581365/ogenki/mobileAbout.png'
export const foodGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1594581365/ogenki/foodGallery.png'
export const foodGalleryVegan =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1639254158/ogenkiVegan/foodGallery.jpg'
export const mobileGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1594581365/ogenki/mobileGallery.png'
export const mobileGalleryVegan =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1640840930/ogenkiVegan/mobileGalleryVegan2.jpg'
export const doorDash =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1627344569/masquesolo/doorDash.png'
export const skipTheDishes =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1627344569/masquesolo/skipDishes.png'
export const uberEats =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1627344569/masquesolo/UberEats.png'
export const tiktok = 'https://d36ru9bg85ogfa.cloudfront.net/tiktok.png'
