import React from 'react'
import { css } from 'emotion'
import { CFImage, CFView, CFSelect, PrimaryModal } from 'components'
import { doorDash, skipTheDishes, uberEats } from 'images'
import { dispatch } from 'store'

export default props => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let uber = null
        let skip = null
        let doordash = null
        if (locationId === 'S9ioaKm2suFF6O2pcnnC') {
          skip = `https://www.skipthedishes.com/ogenki-vegan-sushi-main-street`
          uber = `https://www.ubereats.com/ca/store/ogenki-vegan-sushi/JEC-b3zwUyWrbOMNXmEO9Q?diningMode=PICKUP&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMjEyMDAlMjBBYmJvdHNmb3JkJTIwV2F5JTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyRWpNeE1qQXdJRUZpWW05MGMyWnZjbVFnVjJGNUxDQkJZbUp2ZEhObWIzSmtMQ0JDUXlCV01sTWdObGc0TENCRFlXNWhaR0VpVVJKUENqUUtNZ2tSRVZTc2tVcUVWQkVpenBZMWE4eGlyeG9lQ3hEdXdlNmhBUm9VQ2hJSnNZLWxUSkJLaEZRUlhjRk9TZ3RkQXJNTUVMQUpLaFFLRWduckN4OU1rRXFFVkJGRFRXQTZVR0Q1NEElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNDkuMDM4NjMyNSUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xMjIuMjc2NTYxMiU3RA%3D%3D&rpu=bcd4564d-f7a6-4ed2-a3b7-afb08427b011`
        } else {
          skip = `https://www.skipthedishes.com/ogenki-sushi-fusion-main-street-vancouver`
          doordash =
            'https://www.doordash.com/en-CA/store/ogenki-sushi-fusion-vancouver-24166381/'
          uber = `https://www.ubereats.com/ca/store/ogenki-sushi/17xh_D7UQcGUFr7VXyz4UQ?pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMjEwMzA5JTIwTm8uJTIwMyUyMFJkJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyRWl3eE1ETXdPU0JPYnk0Z015QlNaQ3dnVW1samFHMXZibVFzSUVKRElGWTNRU0F5UlRRc0lFTmhibUZrWVNKUkVrOEtOQW95Q2YxUElTc2c0SVZVRVlKc0JURFBuVDhHR2g0TEVPN0I3cUVCR2hRS0VnbXh2aXg3SC1DRlZCSHI2TjZHdVVUdzRBd1F4VkFxRkFvU0NRWGFPc0JMNElWVUVjdlV3LU1MLTM1WSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E0OS4xMzg2OTY3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTEyMy4xMzczNDklN0Q%3D&utm_campaign=place-action-link&utm_medium=organic&utm_source=google`
        }
        return (
          <PrimaryModal
            title={'Choose Delivery Provider'}
            isOpen={props.showModal}
            onRequestClose={props.onRequestClose}
          >
            <CFView className={styles.container}>
              <CFView column alignCenter w="100%">
                <a href={skip} target="_blank">
                  <CFView hover mt="15px">
                    <CFImage
                      maxWidth="290px"
                      src={skipTheDishes}
                      alt="Door Dash"
                    />
                  </CFView>
                </a>
                <a href={doordash} target="_blank">
                  <CFView hover mt="15px">
                    <CFImage maxWidth="290px" src={doorDash} alt="DoorDash" />
                  </CFView>
                </a>
                <a href={uber} target="_blank">
                  <CFView hover mt="15px">
                    <CFImage maxWidth="290px" src={uberEats} alt="UberEats" />
                  </CFView>
                </a>
              </CFView>
            </CFView>
          </PrimaryModal>
        )
      }}
    </CFSelect>
  )
}

const styles = {
  container: css({
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '400px',
    '@media (max-width: 767px)': {
      padding: '30px',
      width: '330px',
    },
  }),
}
